export function init() {

	var $clientsSlider = document.querySelectorAll('.clients-slider'),
		$partnersSlider = document.querySelectorAll('.partners-slider');

	if($clientsSlider) {
		$('.clients-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 7,
			slidesToScroll: 3,
			autoplay: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1100,
		      	settings: {
			        slidesToShow: 5,
			        slidesToScroll: 2
		      	}
		   	},
		   	{
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 4,
			        slidesToScroll: 2
		      	}
		    },
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($partnersSlider) {
		$('.partners-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 5,
			slidesToScroll: 3,
			pauseOnFocus: false,
			pauseOnHover: false,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1100,
		      	settings: {
			        slidesToShow: 5,
			        slidesToScroll: 2
		      	}
		   	},
		   	{
		      	breakpoint: 800,
		      	settings: {
			        slidesToShow: 4,
			        slidesToScroll: 2
		      	}
		    },
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

}