import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import * as modalEffects from '../functions/modalEffects';

import * as skrollr from 'skrollr';

// require('visible');

// function showMoreRealization() {
// 	var $self = $('.blog-box'),
// 		elementsAmount = $self.size(),
//     	x = 3,
//     	xx = 2;

//     if(elementsAmount > x) {
//     	$('.show-more').addClass('activated');
//     }

//     $('.blog-box:gt('+xx+')').addClass('hidden');

//     $('.show-more').click(function() {
//         x = (x+3 <= elementsAmount) ? x+3 : elementsAmount;
//         $('.blog-box:lt('+x+')').removeClass('hidden');

//         if(x === elementsAmount) {
//         	$(this).removeClass('activated');
//         }

//         return false;
//     });
// }

function checkViewportAndInit() {
	if($(window).width() >= 800){
		// showMoreRealization();

		setTimeout(function(){
			$('.sub-menu').appendTo($('.overlay'));
		}, 100);

		$('.menu-item-has-children').on('click', 'a', function(e){
			e.preventDefault();
			e.stopPropagation();

			$('.overlay').toggleClass('subcat-opened');
		});
	} else {
		$('.menu-item-has-children').addClass('active');

		setTimeout(function(){
			$(document).find('.sub-menu').appendTo($('#overlay-menu .menu-item-has-children'));
		}, 100);

		$('.menu-item-has-children').on('click', '> a', function(e){
			e.preventDefault();
			e.stopPropagation();

			$(this).toggleClass('active');

			$('.sub-menu').stop(0,0).slideToggle();
		});

		$('.contact-link').on('click', function(){
			window.scrollTo(0, 0);
		});
	}
}

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
				overlay.removeClass('subcat-opened');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $fancybox = $('a.fancybox'),
		$map = document.getElementById('map'),
		$contactLink = $('.contact-link'),
		$clientsSlider = document.querySelectorAll('.clients-slider'),
		$partnersSlider = document.querySelectorAll('.partners-slider'),
		$mapHome = document.getElementById('map-home');

	scrollEvent.init();

	if($contactLink.length) {
		modalEffects.init();
	}

	if($partnersSlider || $clientsSlider){
		slickSliders.init();
	}

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	// $('.offer-item__title').on('click', function(){
	// 	let $self = $(this);

	// 	$self.parent().addClass('active');
	// });

	// $('.close-offer').on('click', function(){
	// 	$(this).parent().removeClass('active');
	// });

	if($map || $mapHome) {
		googleMap.init();
	}

});

$(window).on('load', function() {
	
	if(!$('html').hasClass('tablet-view') && !$('html').hasClass('mobile-view')){
		var mySkrollr = skrollr.init({
			edgeStrategy: 'set',
			smoothScrolling: false,
			forceHeight: false
		});
	}

	checkViewportAndInit();

	if($('.form-box').length){
		var dataTitle = $('.section-main__content h1').html();
		$('.input-place-title').val(dataTitle);

		$('.form-box form').on('submit', function(){
			$('.input-place-title').val(dataTitle);
		});
	} 
	$('.contact-link').on('click', function(e){
		var dataTitleH1 = $(this).parent().parent().find('h1').html();
		var dataTitleH2 = $(this).parent().parent().find('h2').html();
		var dataTitleH3 = $(this).parent().parent().find('h3').html();

		if(dataTitleH1 !== '' && typeof dataTitleH1 !== 'undefined') {
			$('.input-place-title').val(dataTitleH1);
		} else if(dataTitleH2 !== '' && typeof dataTitleH2 !== 'undefined'){
			$('.input-place-title').val(dataTitleH2);
		} else if(dataTitleH3 !== '' && typeof dataTitleH3 !== 'undefined'){
			$('.input-place-title').val(dataTitleH3);
		}
	});
	
});

window.addEventListener("orientationchange", function() {
    checkViewportAndInit();

    $('.overlay').removeClass('subcat-opened');

    if($('.menu-item-has-children').hasClass('active')){
		$('.overlay').addClass('subcat-opened');
	};
}, false);