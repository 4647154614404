export function init() {

	var $map = document.getElementById('map'),
		flag = true;

	window.callInitGoogleOptions = function() {
		initGoogleOptions();
	};

	var loadAsyncScript = function() {
		var s = document.createElement('script');

		s.src = '//maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyDc0vCJDTFRC727aQe8KtdgGaMagRRO5kI&callback=callInitGoogleOptions';
		document.body.appendChild(s);
	};

	if($('.google-map--contact').length) {
		$(window).bind('load scroll', function(){
			var mapCanvasOffset = $('#map').offset().top - $(window).scrollTop() - 1000;

			if( $(window).scrollTop() > mapCanvasOffset && !$('body').is('.map-loaded') ) {

				$(document).trigger('load-map');
			}
		});

		$(document).on('load-map', function(){
			$('body').addClass('map-loaded');
			
			loadAsyncScript();
		});
	}
	
	$('.check-map').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();

		$('footer').addClass('map-initialized');
		setTimeout(function(){
			$('footer').addClass('map-z-index')
		}, 700);

		if(flag === true) {
			setTimeout(function(){
				loadAsyncScript();
			}, 700);

			flag = false;
		}
	});

	$('.map-hld').on('click', '.close-btn', function(){
		$('footer').removeClass('map-initialized').removeClass('map-z-index');
	});

	function initGoogleOptions() {
		var myLatLng = {lat: 52.2359085, lng: 21.0048962},
			myOptions = {
	        	zoom: 15,
	        	center: myLatLng,
	        	scrollwheel: false,
                scaleControl: false,
                disableDefaultUI: false,
                styles: [{featureType:"landscape",stylers:[{saturation:-100},{lightness:65},{visibility:"on"}]},{featureType:"poi",stylers:[{saturation:-100},{lightness:51},{visibility:"simplified"}]},{featureType:"road.highway",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"road.arterial",stylers:[{saturation:-100},{lightness:30},{visibility:"on"}]},{featureType:"road.local",stylers:[{saturation:-100},{lightness:40},{visibility:"on"}]},{featureType:"transit",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"administrative.province",stylers:[{visibility:"off"}]},{featureType:"administrative.locality",stylers:[{visibility:"off"}]},{featureType:"administrative.neighborhood",stylers:[{visibility:"on"}]},{featureType:"water",elementType:"labels",stylers:[{visibility:"on"},{lightness:-25},{saturation:-100}]},{featureType:"water",elementType:"geometry",stylers:[{hue:"#ffff00"},{lightness:-25},{saturation:-97}]}],
                mapTypeId: google.maps.MapTypeId.ROADMAP
	        },
	        map = new google.maps.Map($map,myOptions),
	        locations = [
		      ['<h3>IT arte Sp. z o.o.</h3> ul. Zielna 37<br/>00-108 Warszawa', 52.2359085, 21.0048962, 1],
		    ];
	        marker = new google.maps.Marker({
		    	position: myLatLng
		  	});

	  	for (var i = 0; i < locations.length; i++) {  
	      var marker = new google.maps.Marker({
	        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
	        map: map
	      });

		  var infowindow = new google.maps.InfoWindow({
	          content: locations[i][0]
		  });

          infowindow.open(map, marker);

	      google.maps.event.addListener(marker, 'click', (function(marker, i) {
	        return function() {
		        infowindow.open(map, marker);
	        }
	      })(marker, i));
	   	}
	}
}